import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useMemo, useState } from 'react'

import Button from '@/components/Button'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import networksConfig from '../../config/networks'

import styles from './Section.module.scss'
import { useUser } from '@/bus/user'
import { routes } from '@/config'

interface AboutNetworkProps extends DefaultComponentProps {
  id: string
}
export const AboutNetwork: FC<AboutNetworkProps> = ({ className = '', id }) => {
  const [animationStart, setAnimationStart] = useState(false)
  const { t } = useTranslation(`networks`)
  const { isAuthorized } = useUser()
  useEffect(() => {
    let timeout = setTimeout(() => {
      setAnimationStart(true)
    }, 300)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const { color = '#fff', bg: backgroundColor = '#2879F9' } = useMemo(
    () => networksConfig?.[id] ?? {},
    [id]
  )

  return (
    <section
      className={clsx(styles['section'], className, {
        [styles['section_animation-start']]: animationStart
      })}
    >
      <div className={styles['section__content']}>
        <div className={styles['section__column']}>
          <div className={styles['section__column-inner']}>
            <Heading className={styles['section__heading']} level={2}>
              {t(`networks.${id}.full.heading`)}
            </Heading>
            <div
              className={styles['section__summary']}
              dangerouslySetInnerHTML={{
                __html: t(`networks.${id}.full.summary`)
              }}
            ></div>
            <div className={styles['section__auth-links']}>
              {isAuthorized ? (
                <Button
                  href={{
                    pathname: routes.SERVICES,
                    external: true
                  }}
                  as={Link}
                  size={'l'}
                  className={styles['section__button-link']}
                  before={<Icon name="arrows/right/24" />}
                >
                  {t('forms:buttons.services')}
                </Button>
              ) : (
                <Button
                  href={routes.REGISTER}
                  as={Link}
                  size={'l'}
                  className={styles['section__button-link']}
                  before={<Icon name="arrows/right/24" />}
                >
                  {t('forms:buttons.plug')}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={styles['section__column']}>
          <div className={styles['section__column-inner']}>
            <div className={styles['section__hero']}>
              <div className={styles['section__hero-man']}></div>
              <div
                className={styles['section__hero-square']}
                style={{ backgroundColor, color }}
              >
                <div className={styles['section__hero-icon']}>
                  <Icon name={`networks/${id}/${id}`} />
                </div>
                <div className={styles['section__hero-name']}>
                  {t(`networks.${id}.full.heading`)}
                </div>
              </div>
              <div className={styles['section__hero-dollar1']}>
                <Icon name={'networks/bg/dollar1'} />
              </div>
              <div className={styles['section__hero-dollar2']}>
                <Icon name={'networks/bg/dollar2'} />
              </div>
              <div className={styles['section__hero-coin']}>
                <Icon name={'networks/bg/coin'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
